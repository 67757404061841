<template>
    <template v-for="object in dsObject.data" v-if="!readOnly && dsObject.state._isLoaded">
        <div class="px-2 px-lg-3 py-1 py-lg-2 border my-1" style="background-color: cornsilk;">
            <div class="row">
                <div class="col-12">
                    <label class="d-block">{{$t("Object Type")}} {{ object.ObjectType }}</label>
                    <h5 class="mb-1 d-inline">
                        <a target="_blank" :href="`/nt/objectdetails?ID=${object.Object_ID}`">{{ object.ObjectDescription }} ({{ object.Name }})</a>
                    </h5>
                    <ODataLookup class="form-control form-control-sm"
                        :data-object="dsObjects"
                        :bind="setObject"
                        persistentFilterId="logandrespobjectlookup"
                        forceReloadOnOpen
                        focusField="Name">
                        <OColumn field="ID" width="100"></OColumn>
                        <OColumn field="Name" width="200"></OColumn>
                        <OColumn field="Description" width="300"></OColumn>
                        <OColumn field="ObjectType" width="200"></OColumn>
                        <OColumn field="OrgUnit" width="200"></OColumn>
                        <template #target="{ target }">
                            <button :ref="target" class="btn btn-sm btn-link">{{ $t("Select Object") }}</button>
                        </template>
                    </ODataLookup>
                </div>

                <!-- Responsibilities -->
                <template v-if="responsibleForObject(object.Object_ID).length">
                    <div class="col-12">
                        <label class="mb-1 mt-2 col-12 border-bottom fw-bold">{{$t("Responsibilities")}}</label>
                            <div v-for="(item, index) in responsibleForObject(object.Object_ID)" :key="index">
                                <a :href="`/system-person?ID=${item.Person_ID}`">{{item.Person}}</a>
                                <small class="text-muted ms-2"> {{item.Comments}}</small>
                            </div>
                    </div>
                </template>

                <!-- Log Entries -->
                <template v-if="logEntriesForObject(object.Object_ID).length">
                    <div class="col-12">
                        <label class="mb-1 mt-2 col-12 border-bottom fw-bold">{{$t("Log Entries")}}</label>
                            <div v-for="(item, index) in logEntriesForObject(object.Object_ID)" class="text-truncate" :key="index">
                                <small class="d-block">
                                    {{ utils.formatDate(item.Created, "General Date Short Time") }} {{item.CreatedBy}}
                                    <span class="text-muted" v-if="item.Entry">: {{ item.Entry }}</span>
                                </small>
                            </div>
                    </div>
                </template>

                <!-- Workflows -->
                <template v-if="workflowsForObject(object.Object_ID).length">
                    <div class="col-12">
                        <label class="mb-1 mt-2 col-12 border-bottom fw-bold">{{$t("Workflows Connected to Selected Object")}}</label>
                            <div v-for="(item, index) in workflowsForObject(object.Object_ID)" class="text-truncate" :key="index">
                                <small>
                                    <a :class="{'text-decoration-line-through': item.Closed}"
                                        :href="`/nt/${item.DetailApp_ID}?ID=${item.Workflow_ID}`"
                                        target="_blank">{{item.Workflow_ID}}</a>
                                    {{item.ItemCreatedBy}}:
                                    <span class="text-muted">{{ item.WorkflowItem }}</span>
                                </small>
                            </div>
                    </div>
                </template>
            </div>
        </div>
    </template>

    <template v-if="dsObject.data.length === 0">
        <div class="col-12">
            <ODataLookup class="form-control form-control-sm"
                :data-object="dsObjects"
                :bind="setObject"
                forceReloadOnOpen
                persistentFilterId="logandrespobjectlookup"
                focusField="Name">
                <OColumn field="ID" width="100"></OColumn>
                <OColumn field="Name" width="200"></OColumn>
                <OColumn field="Description" width="300"></OColumn>
                <OColumn field="ObjectType" width="200"></OColumn>
                <OColumn field="OrgUnit" width="200"></OColumn>
                <template #target="{ target }">
                    <label :ref="target" class="mb-1 btn" style="cursor:pointer">
                        {{ $t("Set Object") }}
                        <i class="bi bi-caret-down-fill text-muted ms-1"></i>
                    </label>
                </template>
            </ODataLookup>
        </div>
    </template>
</template>


<script setup>   
    import { defineProps } from 'vue';
    import { getOrCreateDataObject } from 'o365-dataobject';
    import { getOrCreateProcedure } from 'o365-modules';
    import { utils } from 'o365-utils';   

    const props = defineProps({
        itemId: Number,
        objectId: Number,
        readOnly:Boolean
    });        

    const procAddObject = getOrCreateProcedure({ id:"procCreateRelatedTo", procedureName:"astp_Scope_ItemsObjectsAdd" });    

    const dsObject = getOrCreateDataObject({
        id: 'dsObject',
        viewName: 'aviw_Scope_ItemsObjects',
        maxRecords: -1,
        distinctRows: false,
        fields:[
            { name: "Object_ID", },
            { name: "Object", type: "string" },
            { name: "ObjectType", type: "string" },
            { name: "Name", type: "string" },
            { name: "ObjectDescription", type: "string" },
            { name: "TargetObject", sortOrder: 1, sortDirection:"desc" }
        ],        
    });

    if(!props.readOnly){
        dsObject.recordSource.whereClause = "Item_ID =" + props.itemId;
        dsObject.load();
    }

    const dsResponsible = getOrCreateDataObject({
        id: 'dsResponsible',
        viewName: 'aviw_Scope_ItemsObjectsResponsibilities',
        maxRecords: -1,
        distinctRows: false,
        fields:
            [
                {name: "Person", type: "string" , "sortOrder": 1, "sortDirection": "asc"},
                {name: "Person_ID", type: "number" },
                {name: "Email", type: "string" },
                {name: "MobileNo", type: "string" },
                {name: "Comments", type: "string" },
                {name: "Object_ID", type: "number" },
                {name: "Object", type: "string" },
            ]
    });

    const dsLog = getOrCreateDataObject({
        id: 'dsLog',
        viewName: 'aviw_Scope_ItemsObjectsLogEntries',
        maxRecords: 10,
        distinctRows: false,
        fields:
             [{name: "Object", type: "string" },
             {name: "Object_ID", type: "number" },
             {name: "CreatedBy_ID", type: "number" },
             {name: "CreatedBy", type: "string" },
             {name: "Created", type: "datetime", "sortOrder": 1, "sortDirection": "desc" },
             {name: "CreatedByEmail", type: "string" },
             {name: "Entry", type: "string" },
             {name: "CreatedByMobileNo", type: "number" },]
    });

    const dsWorkflows = getOrCreateDataObject({
        id: 'dsWorkflows',
        viewName: 'aviw_Scope_ItemsObjects',
        maxRecords: 10,
        distinctRows: false,
        fields:
             [{name: "Workflow_ID", type: "number",  "sortOrder": 1, "sortDirection": "desc"},
             {name: "WorkflowItem", type: "string" },
             {name: "Object_ID", type: "number" },
             {name: "Closed", type: "string" },
             {name: "DetailApp_ID", type: "string" },
             {name: "Created", type: "string" },
             {name: "ItemCreatedBy", type: "string" },
             {name: "ClosedBy", type: "string" },]
    });

    const dsWorkflow = getOrCreateDataObject({
        id: 'dsWorkflow',
        viewName:  'aviw_Scope_ItemsDomain',
        uniqueTable: 'atbv_Scope_Items',
        allowUpdate: true,
        selectFirstRowOnLoad: true,
        maxRecords: 1,
        distinctRows: false,
        fields:
             [{name: "DomainIdPath"},
              {name: "Object_ID"},
              {name: "ID"},
              
             ]
    });

    const dsObjects = getOrCreateDataObject({
        id: 'dsObjects',
        viewName: 'aviw_Assets_Objects',
        maxRecords: 10,
        distinctRows: false,
        fields:
             [{name: "ID", type: "number",  },
             {name: "Name", type: "string", "sortOrder": 1, "sortDirection": "desc"},
             {name: "Description", type: "string" },
             {name: "ObjectType", type: "string" },
             {name: "OrgUnit", type: "string" }]
    });

    if(!props.readOnly){
        dsWorkflow.recordSource.whereClause = "ID =" + props.itemId;       
        dsWorkflow.load();
    }


    dsWorkflow.on("DataLoaded", ()=>{
        if(dsWorkflow.data.length){
            dsObjects.recordSource.whereClause = "OrgUnitIdPath LIKE '" + dsWorkflow.current.DomainIdPath + "%'"
        }
    });

    dsObject.on("DataLoaded", ()=>{
        if(dsObject.data.length){
            dsWorkflows.recordSource.whereClause = "Object_ID =" + dsObject.data[0].Object_ID + " AND Workflow_ID <> " + props.itemId;
            dsWorkflows.load();
            
            dsLog.recordSource.whereClause = "Item_ID =" + props.itemId;
            dsLog.load();

        
            dsResponsible.recordSource.whereClause = "Item_ID =" + props.itemId;
            dsResponsible.load();
        }
    });


    async function setObject(pRow){
        procAddObject.execute({Item_ID:props.itemId, Object_ID:pRow.ID}).then((data)=>{
            dsObject.load();
        });
    }

    function responsibleForObject(objectId) {
    return dsResponsible.data.filter(item => item.Object_ID === objectId);
}

    function logEntriesForObject(objectId) {
        return dsLog.data.filter(item => item.Object_ID === objectId);
    }

    function workflowsForObject(objectId) {
        return dsWorkflows.data.filter(item => item.Object_ID === objectId);
    }

</script>